<template>
  <div class="content_box">
    <div class="search_bar">
      <div>
        <el-button type="success" v-on:click="showForm('add',1)">新增</el-button>
        <el-button type="danger" v-on:click="delItem" :disabled="canEdit" >删除</el-button>
      </div>
    </div>
    <div class="main-box treeC">
      <div class="treeBoxFull green">
        <Vtree :treeData="treeData" @nodeclick="showForm" :emitSearch="true" title="系统模块" ref="trees"></Vtree>
      </div>
      <div class="formBOXFull" v-if="showTreeForm">
        <div class="formBOXHeader blue" v-html="title"></div>
        <div class="formBOX blue">
          <Vform :formItemList="formItemList"  @search="search" :setFormInfo="{inline:false,labelWidth:'100px',formRules:rules}">
            <template v-slot:formButton="formBtn">
              <el-form-item>
                <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit">保存</el-button>
              </el-form-item>
            </template>
          </Vform>
        </div>
      </div>
      <div class="formBOXFull" v-if="showTreeTable" style="width:20%">
        <div class="formBOXHeader">页面操作权限</div>
        <div class="formBOX" style="display: flex;flex-direction: column">
          <div style="margin-bottom: 10px;">
            <el-button type="success"  size="small" @click="showDetailPage=true;modePageType='add',id=0" >新增</el-button>
            <el-button type="danger"  size="small" @click="showDetail('','del')" >删除</el-button>
          </div>
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
            <template v-slot:operation="actionBtn">
              <el-button type="warning"  size="small" @click="showDetail(actionBtn.data,'edit')" >编辑</el-button>
            </template>
          </mainTable>
        </div>
      </div>
    </div>
    <el-dialog v-model="showDetailPage" :title="modePageType=='edit'?'编辑页面操作元素':'添加页面操作元素'" width="700" style="padding-bottom:20px;">
      <Vform :formItemList="formItemPageList"  ref="vform" :setFormInfo="{inline:false,labelWidth:100,formRules:pagerules}" @changeform="changeform" v-if="showDetailPage" >
        <template v-slot:formButton="formBtn">
          <el-form-item>
            <el-button type="primary"  @click="onSubmitPage(formBtn.validateForm)" :disabled="canEdit">确定提交</el-button>
          </el-form-item>
        </template>
      </Vform>
    </el-dialog>
  </div>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/system',
      params:{},
      searchData: {},
      showDetailPage:false,
      treeData:[],
      formItemList:[
        {label: "父节点",type: "input", value: "parentName",defaultValue:'',isDisabled:true},
        {label: "模板编号",type: "input", value: "code",defaultValue:'',placeholder:'系统自编码',isDisabled:true},
        {label: "模板key",type: "input", value: "key",defaultValue:''},
        {label: "模板名称",type: "input", value: "title",defaultValue:''},
        {label: "URL地址",type: "input", value: "url",defaultValue:''},
        {label: "排序（升序）",type: "input", value: "sortNum",defaultValue:''},
        {label: "备注说明",type: "input", value: "remark",defaultValue:''},
        {label: "更新时间",type: "input", value: "created",defaultValue:'',isDisabled:true},
      ],
      mainData: {
        isSerial:false,
        isMultiple:true,
        DataList:[],
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "元素id", width: 80, prop: "elementId" },
          { label: "描述", width: 180, prop: "uaDesc" },
          { label: "说明", minWidth: 100, prop: "remark" },
        ]
      },
      showTreeForm:false,
      showTreeTable:false,
      title:'',
      postData:{},
      modeType:'',
      modePageType:'',
      id:'',// 页面操作权限id
      canEdit:false,
      rules: {
        title: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        key: [{ required: true, message: "请输入模板key", trigger: "blur" }],
        sortNum: [
          { required: true, message: "请输入排序", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: '排序是正整数', trigger: 'blur' },
        ]
      },
      pagerules: {
        uaDesc: [{ required: true, message: "请输入操作名称", trigger: "blur" }],
        elementId: [{ required: true, message: "请输入操作元素id", trigger: "blur" }]
      }
    }
  },
  inject: ['$hasPermission','$handleTabRemove'],
  computed: {
    formItemPageList() {
      return [
        {label: "操作名称", type: "input", value: "uaDesc", defaultValue: ''},
        {label: "操作元素id", type: "input", value: "elementId", defaultValue: ''},
        {label: "说明", type: "input", value: "remark", defaultValue: ''},
        {label: "绑定", type: "checkbox", value: "deps", defaultValue:[], selectOptions: this.mainData.DataList.filter(item=>item.id!=this.id), span: 1, keyValue: ['uaCode', 'uaDesc']},
      ]
    }
  },
  created() {
    this.params.type=this.$route.query.type;
  },
  mounted(){
    this.getListData();
  },
  methods: {
    async onSubmit(validate) {
      const formData = await validate();
      if(!formData.url&&formData.lv>2){
        this.showTipMessage("URL地址不能为空",'warning');
        return;
      }
      let form=cloneObject(formData);
      delete form.parentName;
      let url=this.modeType=='edit'?'/savemodule':'/savemodule';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,{...form,...this.postData}).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.getListData();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});


    },
    async onSubmitPage(validate) {
      const formData = await validate();
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + '/savemoduleop' ,{...formData,modulesCode:this.curItem.code,id:this.id,deps:formData.deps.join(',')}).then(()=>{
        this.showTipMessage(this.id==0?'添加页面权限成功!':'修改页面权限成功！','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.getmoduleopdata();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});


    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/getmoduledata',{code:this.params.type}).then((res)=>{
        res.data.map((item)=>{item.value=item.id;item.label=item.title;})
        this.treeData=this.buildTree(res.data,'code','parentCode','children');
      });
    },
    buildTree(source, id, parentId, children){
      let cloneData = cloneObject(source);
      return cloneData.filter(father => {
        let branchArr = cloneData.filter(child => {
          if(father[id] == child[parentId]){
            child.parentName = father['title']
            return true
          }
        });
        branchArr.length > 0 ? father[children] = branchArr : null
        return father[parentId] == 0
      })
    },
    getmoduleopdata(){
      this.$http.post(this.baseApiUrl+'/getmoduleopdata',{code:this.curItem.code}).then((res)=>{
       this.mainData.DataList=res.data;
      });
    },
    showForm(val){
      this.formItemList.map((item)=>{
        if(val[item.value]!=''&&val[item.value]!=null&&val[item.value]!=undefined){
          item.defaultValue=val[item.value];
        }else{
          item.defaultValue=item.value=='deps'?1:''
        }
        return item;
      })
      if(typeof val=='string'){
        this.curItem=this.$refs.trees.getTreeData();
        if(!this.curItem){
          this.showTipMessage('请先选择节点!','warning');
          return;
        }
        this.modeType='add';
        // this.title=`<span>新增模块(${this.curItem.lv+1})<span>`;
        this.title=`<span>新增模块<span>`;
        this.formItemList[0].defaultValue=this.curItem.title;
        this.postData={id:0,parentCode:this.curItem.code,lv:this.curItem.lv+1,appCode:this.params.type,pName:this.curItem.title}
      }else{
        this.modeType='edit';
        this.title=`编辑模块<span class='textdanger'>${val.status==0?'(禁用)':''}</span>`;
        this.postData={id:val.id,parentCode:val.parentCode,lv:val.lv,pName:val.parentName}
        this.curItem=val;
        this.getmoduleopdata();
      }
      this.showTreeForm=true;
      if((this.curItem.lv==3&&this.modeType=='edit')||(this.curItem.lv+1==3&&this.modeType=='add')){
        this.showTreeTable=true;
      }else{
        this.showTreeTable=false;
      }
    },
    delItem(){
      if(!this.curItem.id){
        this.showTipMessage('请选择要删除的记录!','error');
        return;
      }
      this.showConfirm('是否确认删除所选记录？',()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + "/deletemodule", {id:this.curItem.id,code: this.params.type}).then(()=>{
          this.showTipMessage("删除成功！","success");
          this.canEdit=false;
          this.getListData();
          this.showTreeForm=false;
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
      })
    },
    showDetail(d,type){
      if(type=='edit'){
        this.showDetailPage=true;
        this.modePageType=type;
        this.id=d.id;
        this.formItemPageList.map((item)=>{
          if(d[item.value]!=''&&d[item.value]!=null&&d[item.value]!=undefined){
            item.defaultValue=item.value=='deps'?d[item.value].split(','):d[item.value];
          }else{
            item.defaultValue=item.value=='deps'?[]:''
          }
          return item;
        })
      }else{
        let data =this.$refs.mainTable.select(),ids=[],postData={};
        if(!data) return;
        data.map((item)=>{ids.push(item.id);})
        postData={ids:ids.join(','),code:this.params.type}
        this.showConfirm('是否删除该页面权限',()=>{
          this.canEdit=true;
          this.$http.post(this.baseApiUrl + '/deletemoduleop',postData).then(()=>{
            this.showTipMessage('删除页面权限成功','success');
            this.canEdit=false;
            this.getmoduleopdata();
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
        })
      }
    }
  }
}
</script>
