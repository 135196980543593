import {ElLoading,ElMessage,ElMessageBox} from 'element-plus'
import request from '@/utils/http'
export function showConfirm(msg:string,callback: () => void,confirmButtonText='确定',cancelButtonText='取消'){
    ElMessageBox.confirm(msg, '确认对话框', {
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        type: 'warning'
    }).then(() => {
        callback();
    }).catch();
}
export function loadScripts(_scripts:any, callback:any, nocache:any){
    var __loadedScripts:any = [];
    if (typeof (_scripts) != "object") var _scripts:any = [_scripts];
    var scripts:any = [];
    if (nocache) scripts = _scripts;
    else {
        for (var i in _scripts) {
            if (__loadedScripts.indexOf(_scripts[i]) == -1) scripts.push(_scripts[i]);
        }
        if (scripts.length == 0) {
            if (typeof (callback) == "function") callback();
            return;
        }
    }
    var HEAD = document.getElementsByTagName("head").item(0) || document.documentElement;
    var s = new Array(), last = scripts.length - 1, recursiveLoad = function (i:number) { //递归
        s[i] = document.createElement("script");
        s[i].setAttribute("type", "text/javascript");
        s[i].onload = s[i].onreadystatechange = function () { //Attach handlers for all browsers
            if (!/*@cc_on!@*/0 || this.readyState == "loaded" || this.readyState == "complete") {
                this.onload = this.onreadystatechange = null; this.parentNode.removeChild(this);
                if (i != last) recursiveLoad(i + 1); else if (typeof (callback) == "function") {
                    __loadedScripts = __loadedScripts.concat(scripts);
                    callback();
                }
            }
        }
        s[i].setAttribute("src", scripts[i]);
        HEAD.appendChild(s[i]);
    };
    recursiveLoad(0);
}
export function getMappingName(d:string|number, list:[], idKey?:any, mapKey?:any) {
    idKey = idKey || 'Id';
    mapKey = mapKey || 'Name';
    let it = list.find((v) => { return v[idKey] == d; });
    return it ? it[mapKey] : '';
}
export function cloneObject(o:any, def:any){
    let d='';
    if(def=='Shallow'){
        def = {};
        d = Object.assign({}, o);
        return Object.assign(d, def)
    }else{
        d= JSON.parse(JSON.stringify(o));
    }
    return d;
}
export function compareObjects(oldObj: { [key: string]: any }, newObj: { [key: string]: any }, Keys:any, parentKey = ''){
    if (Keys === undefined) {
        Keys = new Set();
    }
    for (let key of Object.keys(oldObj).concat(Object.keys(newObj))) {
        const childKey = parentKey ? parentKey + '.' + key : key;
        const oldVal = oldObj[key];
        const newVal = newObj[key];
        if (typeof oldVal === 'object' && typeof newVal === 'object') {
            if(JSON.stringify(oldVal) !== JSON.stringify(newVal)){
                Keys.add(key);
            }
            // compareObjects(oldVal, newVal, Keys, childKey);
        } else if (oldVal !== newVal) {
            Keys.add(childKey);
        }
    }
    return Array.from(Keys);
}
export function doFormValidate(vDom:any, formNames:any, callback: () => void){
    if (typeof (formNames) == 'string') formNames = [formNames];
    var list = [];
    for (let i = 0; i < formNames.length; i++) {
        list.push(new Promise((resolve, reject) => {
            vDom.$refs[formNames[i]].validate((valid:any) => {
                if (valid)
                    resolve(true);
                else
                    reject();
            });
        }));
    }
    if (typeof (callback) == 'function') {
        Promise.all(list).then(callback).catch(() => { });
    }
    else
        return Promise.all(list);
}
export function formatAddressData(source:any, ids:number[]){
    let tags: Record<string, any> = [];
    function traverse(arr:any) {
        if(arr){
            for (const item of arr) {
                if (ids.includes(Number(item.value))) {
                    tags.push({ name: item.label, id: item.value });
                }
                if (item.children) {
                    traverse(item.children);
                }
            }
        }
    }
    traverse(source);
    return tags;
}
export function GetFileSize(fileByte:number){
    var fileSizeByte = fileByte;
    var fileSizeMsg = "";
    if (fileSizeByte < 1048576)
        fileSizeMsg = (fileSizeByte / 1024).toFixed(2) + "KB";
    else if (fileSizeByte == 1048576)
        fileSizeMsg = "1MB";
    else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824)
        fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(2) + "MB";
    else if (fileSizeByte > 1048576 && fileSizeByte == 1073741824)
        fileSizeMsg = "1GB";
    else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776)
        fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    else
        fileSizeMsg = "文件超过1TB";
    return fileSizeMsg;
}
export function formatBatch(val:any){
    switch (parseInt(val)) {
        case 1:
            return "先进先出";
        case 3:
            return "模糊批次";
        case 4:
            return "模糊批次";
        case 5:
            return "指定批次";
        case 7:
            return "模糊批次";
    }
}
export function formatBatchDate(produceCondition:string, produceValue1:string, produceValue2:any){
    var produce1:any = new Date(produceValue1);
    var produce2:any = new Date(produceValue2);
    switch (parseInt(produceCondition)) {
        case 1:
            return "";
        case 3:
            return "> " + formatTime(produce1);
        case 4:
            return "< " + formatTime(produce1);
        case 5:
            return formatTime(produce1);
        case 7:
            return formatTime(produce1) + " ~ " + formatTime(produce2);
    }
}
export function AjaxLoading (msg?:string) {
    const loadingInstance= ElLoading.service({
        lock: true,
        text: msg||'正在加载中',
        background: 'rgba(0, 0, 0, 0.6)',
    })
    return loadingInstance;
}
export function AjaxLoadEnd (loadingInstance:any){
    if (loadingInstance && typeof loadingInstance.close === 'function') {
        loadingInstance.close();
    }
}
export function formatTime(t:string,n?:number,fmt = 'yyyy-mm-dd'){
    if (typeof (t) == 'string') t = t.replace(/-/g, "/");
    let ret,date;
    if (t) {date = new Date(t)}
    else{date = new Date()}
    if (n) date = new Date(date.valueOf() + n * 24 * 3600 * 1000);
    let opt:any = {
        'y+' :date.getFullYear(),
        'm+' : date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
        'd+' : date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate(),
        'h+': date.getHours(),
        'M+': date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes(),
        's+':  date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds()
    }
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (opt[k]))
        };

    };
    return fmt
}
export function showTipMessage(msg:string, msg_type?:any){
    ElMessage({
        grouping: true,
        message: msg,
        type: msg_type||'error',
    });
}
const GRID_OP_MAP: { [key: string]: string } = { delete: '删除', check: '审核', uncheck: '取消审核', unlock: '解锁' };
export function bulkOperateRecord(url:string, data:any, op:any, primaryKey:any, nameKey:any, callback:() => void, ext:any){
    var opTxt = GRID_OP_MAP[op] || op;
    var postData = ext || {};
    var arr:any = [];
    data.forEach((v:{ [key: string]: any }) => {
        arr.push(v[primaryKey]);
    });
    postData[nameKey] = ext.__isArray ? arr : arr.join(',');
    showConfirm('确认 ' + opTxt + ' 所选项目？', ()=> {
        request.post(url, postData).then(()=> {
            showTipMessage('操作成功', 'success');
            if (typeof (callback) == 'function') callback();
        });
    });
}

