<template>
  <div class="content_box" style="padding-top: 10px;">
    <div class="search_bar">
      <el-button type="warning" @click="exportData" size="small">导出</el-button>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
    </div>
  </div>
</template>
<script>

    export default {
        data() {
            return {
                baseApiUrl: '/finance',
                OrderNo:'',
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { prop: "orderNo", label: '城批单号', width: 210 },
                        { prop: "orderTypeName", label: '单据类型', width: 130 },
                        { label: '入账时间', width: 100,  callBack: (item)=>{
                                return this.$formatDate(item.deliveryTime);
                            }
                        },
                        { prop: "productCode", label: '商品编码', width: 100 },
                        { prop: 'productName', label: '商品名称', width: 200 },
                        { prop: 'itemSerialCode', label: '条目编码', width: 90 },
                        { prop: 'unitPrice', label: '单价', width: 70 },
                        { prop: 'unitQty', label: '数量', width: 70 },
                        { prop: 'amount', label: '金额', width: 80 },
                        { prop: 'shipPrice', label: '运费', width: 80 },
                        { prop: "DeliveryMoney", label: '驿站配送提成', width: 100 },
                        { prop: 'orderAmount', label: '合计金额', width: 80 },
                        { prop: 'transactionSerialNum', label: '微信交易流水号', width: 230 },
                        { label: '收款时间', width: 100,  callBack: (item)=>{
                                return this.$formatDate(item.createdTime);
                            }
                        },
                        { prop: 'spec', label: '规格', width: 70 },
                        { prop: 'barcode', label: '商品条码', width: 100 },
                        { prop: 'stageName', label: '驿站名称', minWidth: 150 }
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },
            }
        },
      created() {
          this.OrderNo=this.$route.query.OrderNo
      },
      mounted() {
            this.getListData()
        },
        methods: {
            getListData() {
                this.$http.post(this.baseApiUrl + '/GetStageFreightRevenueDetails', {...this.pageinfo,orderNo:this.OrderNo}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
            exportData(){
                this.postExportData({orderNo:this.OrderNo}, this.baseApiUrl+'/GetStageFreightRevenueDetails')
            },
        }
    }
</script>
